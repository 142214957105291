"use strict";

import $ from 'jquery';
import fetch from "@elements/fetch";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import initModulesInScope from '@elements/init-modules-in-scope';
import * as wishlistRequest from './wishlist-request';

export function initInScope($scope) {

    let $wishlist = $scope.find('.js-wishlist');
    let $loading = $wishlist.find('.js-wishlist__loading');
    let $remove = $wishlist.find('.js-wishlist__remove');
    let $result = $wishlist.find('.js-wishlist__result');
    let pendingRequest;

    $remove.on('click', function(e){
        $loading.removeAttr('hidden');

        let params = getPrefixedDataSet("wishlist", $(this));

        pendingRequest = fetch($(this).data('action'), {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
            },
            body: new URLSearchParams(params)
        });

        pendingRequest.then(function(res){

            return res.clone().json();

        }).then(function(res){
            $result.html(res.html);
            $loading.prop('hidden', true);
            initModulesInScope($scope);
            wishlistRequest.init();
        }).catch(function(error){
            console.error(error);
        });
    });

}
