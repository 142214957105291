"use strict";

import $ from 'jquery';
import fetch from "@elements/fetch";
import initModulesInScope from "@elements/init-modules-in-scope";
import {showNotification} from '@elements/alert-notification';
import {trackEvent} from "./trackingGa4/gtm";


export function init(){
    let $count = $('.js-wishlist__count');
    if ($count && $count.length) {
        let request = fetch($count.data('action'), {
            method: 'GET'
        });
        showNotification(request);

        request.then(response => response.json())
            .then(result => {
                if (result.success) {
                    if (typeof result.count !== "undefined") {
                        setCount($count, result.count);
                    }
                }
            }).catch(e => {
            console.warn(e);
        });
    }
}
export function initInScope($scope) {

    $scope.find('.js-wishlist-request__btn').on('click', function(e){

        let $container = $(this).closest('.js-wishlist-request'),
            btnValue = $(this).data('value'),
            //$requestLoading = $container.find('.js-wishlist-request__loading'),
            $result = $scope.find('.js-wishlist-request__result'),
            $count = $('.js-wishlist__count'),
            loginModal = $scope.find('.js-modal-login'),
            wishlistLoginModal = loginModal.addClass('wishlist-login'),
            $modalInput = wishlistLoginModal.find('.js-wishlist-login__input');

        if(wishlistLoginModal.hasClass('wishlist-login')){
            $modalInput.val(btnValue);
        }

        //$requestLoading.removeAttr('hidden');

        let pendingRequest = fetch($(this).data('action'), {
            method: 'GET'
        });


        pendingRequest.then(function(res){
            return res.clone().json();
        }).then(function(res){
            if(res.success){
                if (typeof res.count !== "undefined") {
                    setCount($count, res.count);
                }
                if(res.trackingData){
                    trackEvent(res.trackingData, {debug: true});
                }
            }
            if(res.html){
                $result.each(function(i){
                    $(this).html(res.html);
                });
            }
            else if(res.content){
                $result.each(function(i){
                    $(this).html(res.content);
                });
            }
            else{
                console.log('no html or content response from request');
            }

            // $requestLoading.prop('hidden', true);
            initModulesInScope($('.js-wishlist-request'));
        }).catch(function(error){
            console.error(error);
        })
    });

    let loginModal = $scope.find('.js-modal-login');
    loginModal.on('hide.bs.modal', function () {
        $(this).removeClass('wishlist-login');
        loginModal.find('.js-wishlist-login__input').val( '');
    });
}
function setCount($element, count) {
    if (count) {
        $element.attr('hidden', null);
        $element.text(count);
    } else {
        $element.attr('hidden', 'hidden');
    }
}