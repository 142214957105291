"use strict";

export function initInScope($scope) {
    $('#countrySuggestionModal').each(function () {
        let $modal = $(this);
        let currentCountry = $modal.data('country-suggestion-modal-current');
        let detectedCountry = $modal.data('country-suggestion-modal-detected');
        let currentSessionCountry = window.localStorage.getItem('currentCountry');

        if(!window.sessionStorage.getItem('countryModal') && (!currentSessionCountry || currentSessionCountry !== detectedCountry) && currentCountry !== detectedCountry) {
            $modal.modal('show');
        }

        $modal.find('.js-country-suggestion-modal__suggestion-btn').on('click', function () {
            closeModal($modal, detectedCountry);
        });

        $modal.find('.js-country-suggestion-modal__close-btn').on('click', function () {
            closeModal($modal, currentCountry);
        });

        $modal.find('.js-country-suggestion-modal__lang-switch-btn').on('click', function () {
            $modal.modal('hide');
            $($(this).data('country-suggestion-modal-target')).modal('show');
        });
    });
}

function closeModal($modal, countrySelect) {
    $modal.modal('hide');
    window.sessionStorage.setItem('countryModal', true);
    window.sessionStorage.setItem('countrySelect', countrySelect);
    if(!window.localStorage.getItem('currentCountry')) {
        window.localStorage.setItem('currentCountry', countrySelect);
    }
}