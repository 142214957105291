import $ from 'jquery';
import 'parsleyjs';
import { toHash } from "@elements/hashing-sha256";
import {getPrefixedDataSet} from "@elements/data-set-utils"

const defaultSelectors = {
    base: '.js-form-tracking',
    input: '.js-form-tracking__input',
    select: '.js-form-tracking__select',
    checkbox: '.js-form-tracking__checkbox',
    btn: '.js-form-tracking__submit-btn',
};

const defaultOptions = {
    debug: false,
    formEvent: 'data-form-tracking-formEvent',
};

export function initInScope($scope, selectors = defaultSelectors, options = defaultOptions) {
    let $forms = $scope.find(selectors.base);
    $forms.each(function() {
        let $form = $(this);
        $form.parsley();
        //console.log('Form found:', $form);
        $form.on($form.attr(options.formEvent), function (e) {
            let isValid = $(this).parsley().validate();
           // console.log('Form submitted:', isValid);
            if (window.dataLayer && isValid) {
                window.dataLayer.push({
                    'event': $form.attr('data-form-tracking-event'),
                    'form_sent': $form.attr('data-form-tracking-form_sent'),
                    'leadsUserData': getLeadsUserData($form, selectors),
                });
            } else {
                console.error('dataLayer is not defined');
            }

            if (options.debug) {
                console.log('dataLayer: ', window.dataLayer);
                getLeadsUserData($form, selectors);
            }
        });
    });
};


function getLeadsUserData($form, selectors) {
    let inputs = $form.find(selectors.input);
    //console.log('inputs', inputs);
    let leadsUserData = {};

    inputs.each(function() {
        let $input = $(this);
        let dataset = getPrefixedDataSet('form-tracking', $input);

        if (dataset.attribute && ($.type(dataset.attribute) === 'string')) {
            if (dataset.attribute.includes('sha256_')) {
                leadsUserData[dataset.attribute] = toHash($input.val().trim().toLowerCase());
            } else {
                leadsUserData[dataset.attribute] = $input.val();
            }
        } else if (dataset.attributeAddress && ( $.type(dataset.attributeAddress) === 'string') ) {
            if (!leadsUserData.address) {
                leadsUserData.address = {};
            }

            if (dataset.attributeAddress.includes('sha256_')) {
                leadsUserData.address[dataset.attributeAddress] = toHash($input.val().trim().toLowerCase());
            } else {
                leadsUserData.address[dataset.attributeAddress] = $input.val();
            }
        } else if (dataset.attributeCharacteristics && ( $.type(dataset.attributeCharacteristics) === 'string') ) {
            if (!leadsUserData.characteristics) {
                leadsUserData.characteristics = {};
            }

            if (dataset.attributeCharacteristics.includes('sha256_')) {
                leadsUserData.characteristics[dataset.attributeCharacteristics] = toHash($input.val().trim().toLowerCase());
            } else {
                leadsUserData.characteristics[dataset.attributeCharacteristics] = $input.val();
            }
        }
    });
   // console.log('leadsUserData', leadsUserData);
    return leadsUserData;
}